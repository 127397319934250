<template>
  <v-row
    class="pa-0 ma-0 fill-height white"
    :style="{ 'min-height': '250px' }"
    no-gutters
  >
    <v-col cols="12" align="center" class="pa-5">
      <v-text-field
        v-model="dateRangeText"
        label="Période"
        prepend-icon="mdi-calendar"
        @focus="toggleDatePicker"
        readonly
      ></v-text-field>
      <v-date-picker
        v-show="showDatePicker"
        v-model="dates"
        class="elevation-1"
        range
        header-color="primary"
        color="secondary"
      ></v-date-picker>
    </v-col>
    <v-col v-show="showDatePicker" cols="12" align="center" class="pa-5">
      <v-btn class="ma-3" @click="toggleDatePicker">Annuler</v-btn>
      <v-btn
        class="ma-3"
        @click="
          loadStats(true)
          toggleDatePicker()
        "
        >Valider</v-btn
      >
    </v-col>
    <v-col
      v-for="st in stateList"
      :key="st"
      cols="12"
      class="px-12 mx-auto my-3"
    >
      <apexchart
        v-if="!loading[st]"
        height="300"
        type="bar"
        :options="optionsTotalPerSource[st]"
        :series="seriesTotalPerSource[st]"
      ></apexchart>
      <v-progress-circular
        class="ma-auto mt-5 d-block"
        color="secondary"
        size="100"
        width="10"
        v-else
        indeterminate
      />
    </v-col>
    <v-col id="chart" cols="3" class="pa-5 mx-auto my-3">
      <apexchart
        v-if="stats"
        type="donut"
        :options="chartOptions"
        :series="series"
      ></apexchart>
      <v-progress-circular
        class="ma-auto mt-5 d-block"
        color="secondary"
        size="100"
        width="10"
        v-else
        indeterminate
      />
    </v-col>
    <v-col id="chart" cols="3" class="pa-5 mx-auto my-3">
      <apexchart
        v-if="stats"
        type="donut"
        :options="chartAddressRatioOptions"
        :series="seriesAddressRatio"
      ></apexchart>
      <v-progress-circular
        class="ma-auto mt-5 d-block"
        color="secondary"
        size="100"
        width="10"
        v-else
        indeterminate
      />
    </v-col>
  </v-row>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions } from 'vuex'
import { fluxItemsStates } from '@/helpers/fluxItemsHelpers'
import { get, keyBy } from 'lodash-es'

export default {
  name: 'TSDashboardCharts',
  props: {},
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartAddressRatioOptions: {
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.1,
          },
        },
        title: {
          text: 'Annonces avec ou sans adresse',
          align: 'center',
        },
        colors: [
          fluxItemsStates.validated.color,
          fluxItemsStates.rejected.color,
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
        },
        fill: {
          opacity: 0.9,
          type: 'solid',
        },
        labels: ['Avec adresse', 'Sans adresse'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '12px',
          floating: false,
          fontFamily: 'roboto',
          fontWeight: 100,
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: true,
        },
      },
      chartOptions: {
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.1,
          },
        },
        title: {
          text: 'Répartition des annonces par état',
          align: 'center',
        },
        colors: [
          fluxItemsStates.treated.color,
          fluxItemsStates.validated.color,
          fluxItemsStates.skipped.color,
          fluxItemsStates.rejected.color,
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
        },
        fill: {
          opacity: 0.9,
          type: 'solid',
        },
        labels: [
          fluxItemsStates.treated.label,
          fluxItemsStates.validated.label,
          fluxItemsStates.skipped.label,
          fluxItemsStates.rejected.label,
        ],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '12px',
          floating: false,
          fontFamily: 'roboto',
          fontWeight: 100,
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
      dates: [],
      selectedDept: null,
      chartTitles: {
        created: "Nb d'annonces créées par jour",
        treated: "Nb d'annonces traitées ( ou passées ) par jour",
        validated: "Nb d'annonces validées par jour",
        new: 'Nb de nouvelles parcelles et doublons par jour',
        ratiodoublon: 'Ratio doublons vs annonces validées (%)'
      },
      stateList: ['created', 'treated', 'validated', 'new', 'ratiodoublon'],
      sourceList: {
        created: ['bienici', 'seloger', 'leboncoin', 'bellesdemeures', 'lefigaro', 'proprietes_lefigaro'],
        treated: ['skipped', 'treated', 'rejected', 'validated'],
        validated: ['bienici', 'seloger', 'leboncoin', 'bellesdemeures', 'lefigaro', 'proprietes_lefigaro'],
        new: ['nouvelles parcelles', 'doublons'],
        ratiodoublon: []
      },
      colors: {
        created: ['#FBBA20', '#E00030', '#FF6E14', '#AED581', '#14617E', '#0096c7', '#155E95'],
        treated: [
          fluxItemsStates.skipped.color,
          fluxItemsStates.treated.color,
          fluxItemsStates.rejected.color,
          fluxItemsStates.validated.color,
          fluxItemsStates.treated.color,
        ],
        validated: ['#FBBA20', '#E00030', '#FF6E14', '#AED581', '#14617E', '#0096c7', '#155E95'],
        new: ['#34C2B8', '#E00030', '#14617E'],
        ratiodoublon: ['#14617E']
      },
      seriesTotal: {},
      seriesTotalPerSource: {},
      optionsTotalPerSource: {},
      loading: {
        created: true,
        treated: true,
        validated: true,
      },
      showDatePicker: false,
    }
  },
  watch: {},
  async mounted() {
    this.loadStats()
  },
  computed: {
    ...mapGetters({
      stats: 'stats/stats',
      fluxItemStats: 'stats/fluxItemStats',
    }),
    series() {
      if (this.stats) {
        let treated = this.stats.counters.treated || 0
        let validated = this.stats.counters.validated || 0
        let rejected = this.stats.counters.rejected || 0
        let skipped = this.stats.counters.skipped || 0

        return [treated, validated, skipped, rejected]
      } else {
        return []
      }
    },
    seriesAddressRatio() {
      if (this.stats) {
        let without =
          get(this.stats, 'unknownAddressCount.countWithoutAddress') || 0
        let validated =
          get(this.stats, 'unknownAddressCount.countValidated') || 0
        return [validated - without, without]
      } else {
        return []
      }
    },
    dateRangeText() {
      return this.dates.join(' ==> ')
    },
  },
  methods: {
    ...mapActions({
      getStats: 'stats/getStats',
      getFluxItemsStats: 'stats/getFluxItemsStats',
    }),
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker
    },
    getDaysArray(start, end) {
      let date = []
      let categories = []
      for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        const d = new Date(dt)
        categories.push(new Date(dt).toLocaleDateString())
        date.push(
          `${d.getFullYear()}${
            d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
          }${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`
        )
      }
      return { date, categories }
    },
    async loadStats(force = false) {
      for (let st of this.stateList) {
        this.loadFluxItemsChart({ type: st, force })
      }
    },
    async loadFluxItemsChart(options) {
      this.loading[options.type] = true
      const startDate =
        this.dates.length < 2
          ? new Date(Date.now() - 20 * 3600 * 1000 * 24)
          : new Date(this.dates[0])
      const endDate =
        this.dates.length < 2 ? new Date(Date.now()) : new Date(this.dates[1])

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      const params = {
        startDate,
        endDate: new Date(endDate.getTime() + 3600 * 1000 * 24),
        type: options.type,
      }

      if (!this.fluxItemStats[params.type].length || options.force) {
        await this.getFluxItemsStats({ params })
      }

      const perDay = keyBy(this.fluxItemStats[params.type], 'day')
      const { date, categories } = this.getDaysArray(startDate, endDate)

      this.seriesTotal[options.type] = {
        data: [],
        type: 'line',
        name: 'total',
      }

      this.seriesTotalPerSource[options.type] = []

      const seriesSource = {}

      this.sourceList[options.type].forEach((source) => {
        seriesSource[source] = {
          data: [],
          type: 'column',
          name: source,
        }
      })

      date.forEach((day) => {
        const dayData = perDay[day]
        const total = get(dayData, 'stats.total')
        this.seriesTotal[options.type].data.push(total || 0)

        const details = get(dayData, 'stats.detail')

        this.sourceList[options.type].forEach((source) => {
          const total =
            !['new','treated'].includes(options.type)
              ? get(details, `${source}.total`)
              : get(details, `${source}`)
          seriesSource[source].data.push(total || 0)
        })
      })

      this.sourceList[options.type].forEach((source) => {
        this.seriesTotalPerSource[options.type].push(seriesSource[source])
      })

      this.seriesTotalPerSource[options.type].push(
        this.seriesTotal[options.type]
      )

      this.optionsTotalPerSource[options.type] = {
        chart: {
          height: 200,
          type: 'line',
          toolbar: {
            show: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        markers: {
          size: 6,
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: this.chartTitles[options.type],
          align: 'left',
          offsetX: 0,
        },
        colors: this.colors[options.type],
        xaxis: {
          categories,
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          position: 'right',
          offsetY: 40,
          fontSize: '12px',
          floating: false,
          fontFamily: 'roboto',
          fontWeight: 100,
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
      }

      this.loading[options.type] = false
    },
  },
}
</script>
<style lang="scss"></style>
