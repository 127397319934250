<template>
  <v-row no-gutters class="pa-4">
    <v-col cols="12" align="center" class="pa-5">
      <v-text-field
        v-model="dateRangeText"
        label="Période"
        prepend-icon="mdi-calendar"
        @focus="toggleDatePicker"
        readonly
      ></v-text-field>
      <v-date-picker
        v-show="showDatePicker"
        v-model="dates"
        class="elevation-1"
        range
        header-color="primary"
        color="secondary"
      ></v-date-picker>
    </v-col>
    <v-col v-show="showDatePicker" cols="12" align="center" class="pa-5">
      <v-btn class="ma-3" @click="toggleDatePicker">Annuler</v-btn>
      <v-btn
        class="ma-3"
        @click="
          load(currentFlux.id)
          toggleDatePicker()
        "
        >Valider</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-progress-circular
        v-if="loading"
        class="ma-auto mt-5 d-block"
        color="secondary"
        size="100"
        width="10"
        indeterminate
      />
      <div v-else>
        <v-row
          v-for="u in Object.keys(stats)"
          :key="u"
          id="chart"
          class="pa-0 ma-0"
        >
          <v-col cols="12">
            <h3>{{ u }}</h3>
          </v-col>
          <v-col cols="6">
            <apexchart
              type="bar"
              :options="chartOptions[u]"
              :series="series[u]"
            ></apexchart>
          </v-col>
          <v-col cols="6">
            <apexchart
              type="line"
              :options="chartOptionsTimes[u]"
              :series="seriesTime[u]"
            ></apexchart>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fluxItemsStates as fsi } from '@/helpers/fluxItemsHelpers'
import { groupBy, keyBy } from 'lodash-es'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'TSOperatorChartWidget',
  props: {},
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: false,
      series: {},
      seriesTime: {},
      stats: {},
      chartOptions: {},
      chartOptionsTimes: {},
      dates: [],
      showDatePicker: false,
    }
  },
  computed: {
    ...mapGetters({
      currentFlux: 'flux/currentFlux',
      operatorStats: 'stats/operatorStats',
    }),
    dateRangeText() {
      return this.dates.join(' ==> ')
    },
  },
  mounted() {
    this.setOperatorStats(null)
    if (this.currentFlux) {
      this.load(this.currentFlux.id)
    }
  },
  methods: {
    ...mapActions({
      getOperatorStats: 'stats/getOperatorStats',
    }),
    ...mapMutations({
      setOperatorStats: 'stats/SET_OPERATOR_STATS',
    }),
    getDaysArray(start, end) {
      let arr = []
      for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        const d = new Date(dt)
        arr.push(`${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${String(d.getFullYear()).padStart(4, '0')}`)
      }
      return arr
    },
    async load(fluxId) {
      this.loading = true
      const startDate =
        this.dates.length < 2
          ? new Date(Date.now() - 7 * 3600 * 1000 * 24)
          : new Date(this.dates[0])
      const endDate =
        this.dates.length < 2 ? new Date(Date.now()) : new Date(this.dates[1])

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      const params = {
        startDate,
        endDate: new Date(endDate.getTime() + 3600 * 1000 * 24),
      }
      await this.getOperatorStats({ fluxId, params })
      this.stats = groupBy(this.operatorStats, 'email')

      for (const u in this.stats) {
        const perDay = keyBy(this.stats[u], 'day')
        const categories = this.getDaysArray(startDate, endDate)

        const series = [
          {
            name: fsi.skipped.label,
            data: [],
          },
          {
            name: fsi.treated.label,
            data: [],
          },
          {
            name: fsi.rejected.label,
            data: [],
          },
          {
            name: fsi.validated.label,
            data: [],
          },
        ]

        const seriesTime = [
          {
            name: fsi.skipped.label,
            data: [],
          },
          {
            name: fsi.treated.label,
            data: [],
          },
          {
            name: fsi.rejected.label,
            data: [],
          },
          {
            name: fsi.validated.label,
            data: [],
          },
        ]

        categories.forEach((day) => {
          const dayData = perDay[day]
          series[0].data.push(
            dayData && dayData.details.skipped
              ? dayData.details.skipped.count
              : 0
          )
          series[1].data.push(
            dayData && dayData.details.treated
              ? dayData.details.treated.count
              : 0
          )
          series[2].data.push(
            dayData && dayData.details.rejected
              ? dayData.details.rejected.count
              : 0
          )
          series[3].data.push(
            dayData && dayData.details.validated
              ? dayData.details.validated.count
              : 0
          )
          seriesTime[0].data.push(
            dayData && dayData.details.skipped
              ? dayData.details.skipped.averageTime
              : 0
          )
          seriesTime[1].data.push(
            dayData && dayData.details.treated
              ? dayData.details.treated.averageTime
              : 0
          )
          seriesTime[2].data.push(
            dayData && dayData.details.rejected
              ? dayData.details.rejected.averageTime
              : 0
          )
          seriesTime[3].data.push(
            dayData && dayData.details.validated
              ? dayData.details.validated.averageTime
              : 0
          )
        })

        this.series[u] = series
        this.chartOptions[u] = {
          chart: {
            type: 'bar',
            height: 300,
            stacked: true,
            toolbar: {
              show: true,
            },
          },
          title: {
            text: "Nombre d'items traités par jour",
            align: 'left',
          },
          colors: [
            fsi.skipped.color,
            fsi.treated.color,
            fsi.rejected.color,
            fsi.validated.color,
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories,
          },
          legend: {
            position: 'right',
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        }

        this.seriesTime[u] = seriesTime
        this.chartOptionsTimes[u] = {
          chart: {
            type: 'line',
            height: 300,
            toolbar: {
              show: true,
            },
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          title: {
            text: 'Tps moyen par item (en s)',
            align: 'left',
          },
          colors: [
            fsi.skipped.color,
            fsi.treated.color,
            fsi.rejected.color,
            fsi.validated.color,
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          xaxis: {
            categories,
          },
          legend: {
            position: 'right',
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        }
      }

      this.loading = false
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker
    },
  },
}
</script>
<style lang="scss" scoped></style>
