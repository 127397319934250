<template>
  <v-row class="pa-0 fill-height" :style="{ 'min-height': '350px' }" no-gutters>
    <v-col id="sw-wrapper" cols="12" class="pa-0 ma-0"> </v-col>
    <div class="dateChooser">
      <v-select v-if="times.length > 1" v-model="selectedPano" :items="times" @change="changeDate" hide-details filled
        dense dark label="Changer l'année" />
    </div>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'
import { findIndex, get, map } from 'lodash-es'
export default {
  name: 'TSStreetViewWidget',
  props: {
    ad: {
      type: Object,
      required: true,
    },
    proofMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      panorama: null,
      sv: null,
      selectedPano: null,
    }
  },
  created() {
    this.$root.$on('resizeMap', this.resize)
    this.$root.$on('forceStreetView', this.setStreetViewPosition)
  },
  destroyed() {
    this.$root.$off('resizeMap', this.resize)
    this.$root.$off('forceStreetView', this.setStreetViewPosition)
  },
  mounted() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
      version: 'weekly',
    })
    /* eslint-disable */
    if (this.$root.panorama) {
      const swContainer = document.querySelector('#sw-container');
      const swContainerDest = document.querySelector('#sw-wrapper');
      swContainerDest.appendChild(swContainer);

      if (this.currentProof && this.currentProof.streetViewData) {
        this.$root.panorama.setPano(this.currentProof.streetViewData.pano)
        this.$root.panorama.setPosition(this.currentProof.streetViewData.position)
        this.$root.panorama.setPov(this.currentProof.streetViewData.pov)
      }

      this.$emit('loaded')
    } else {
      loader.load().then(() => {
        this.$root.sv = new google.maps.StreetViewService()
        this.$root.panorama = new google.maps.StreetViewPanorama(
          document.getElementById("sw-container"),
          {
            pov: {
              heading: 0,
              pitch: 0,
            },
            addressControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_LEFT,
            },
            imageDateControl: true,
            fullscreenControl: false,
            enableCloseButton: false
          }
        )

        const swContainer = document.querySelector('#sw-container');
        const swContainerDest = document.querySelector('#sw-wrapper');
        swContainerDest.appendChild(swContainer);

        this.$root.panorama.addListener("pano_changed", () => {
          const pano = this.$root.panorama.getPano()
          this.$root.sv.getPanorama({ pano }, (data, status) => this.setStreetViewData({ ...this.streetViewData, ...{ pano, time: data.time } }))
        })

        this.$root.panorama.addListener("position_changed", () => {
          const position = { lat: this.$root.panorama.getPosition().lat(), lng: this.$root.panorama.getPosition().lng() }
          this.setStreetViewData({ ...this.streetViewData, ...{ position } })
          this.$root.$emit('setPositionSV', position)
        })

        this.$root.panorama.addListener("pov_changed", () => {
          const pov = this.$root.panorama.getPov()
          this.setStreetViewData({ ...this.streetViewData, ...{ pov } })
          this.$root.$emit('setPovSV', pov)
        })

        if (this.currentProof && this.currentProof.streetViewData) {
          this.$root.panorama.setPano(this.currentProof.streetViewData.pano)
          this.$root.panorama.setPosition(this.currentProof.streetViewData.position)
          this.$root.panorama.setPov(this.currentProof.streetViewData.pov)
        }

        this.$emit('loaded')
      })
    }
  },
  destroyed() { },
  watch: {
    currentMatch(newMatch) {
      if (newMatch && !this.proofMode) {
        const coord = get(newMatch, 'geomCenter.coordinates')
        if (newMatch.customData.streetViewData) {
          this.$root.panorama.setPosition(newMatch.customData.streetViewData.position)
          this.$root.panorama.setPov(newMatch.customData.streetViewData.pov)
        } else {
          this.setStreetViewPosition(coord[1], coord[0])
        }
      }
    },
    currentProof: {
      handler(newProof) {
        if (newProof && newProof.streetViewData) {
          this.$root.panorama.setPano(newProof.streetViewData.pano)
          this.$root.panorama.setPosition(newProof.streetViewData.position)
          this.$root.panorama.setPov(newProof.streetViewData.pov)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      streetViewData: 'matches/streetViewData',
      currentMatch: 'matches/currentMatch',
      currentProof: 'matches/currentProof'
    }),
    times() {
      if (this.streetViewData && this.streetViewData.time && this.streetViewData.time.length) {
        // google time keys always changes so we need to detect it dynamically

        const keys = Object.keys(this.streetViewData.time[0])
        const i = findIndex(keys, k => { return k !== 'pano'; })

        return map(this.streetViewData.time, (t) => ({
          text: new Date(Date.parse(t[keys[i]])).toLocaleDateString(),
          value: t.pano
        }))
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapMutations({
      setStreetViewData: 'matches/SET_STREET_VIEW_DATA',
      setCurrentMatch: 'matches/SET_CURRENT_MATCH',
    }),
    processSVData(data, status, heading = true) {
      if (status === google.maps.StreetViewStatus.OK) {
        this.$root.panorama.setPano(data.location.pano)
        if (heading) {
          const coord = get(this.currentMatch, 'geomCenter.coordinates')
          const pos = new (google.maps.LatLng)(coord[1], coord[0])
          const heading = google.maps.geometry.spherical.computeHeading(data.location.latLng, pos)
          const pov = {
            heading: heading,
            pitch: 0,
            zoom: 0,
          }
          this.$root.panorama.setPov(pov)
        }

        this.$root.panorama.setVisible(true)
        this.setStreetViewData(data)
      } else {
        this.$root.$emit('openSnackBar', {
          message: `Street View impossible à cet endroit`,
          color: 'error',
        })
        this.setStreetViewData(null)
      }
    },
    resize() {
      setTimeout(() => {
        google.maps.event.trigger(this.$root.panorama, 'resize')
      }, 100)
    },
    setStreetViewPosition(lat, lng) {
      this.$root.sv.getPanorama({ location: { lat, lng }, radius: 80, source: google.maps.StreetViewSource.OUTDOOR }, this.processSVData)
    },
    changeDate() {
      this.$root.sv.getPanorama({ pano: this.selectedPano }, (data, status) => this.processSVData(data, status, false))
    },
  },
}
</script>
<style lang="scss" scoped>
.dateChooser {
  position: absolute;
  height: fit-content;
  bottom: 20px;
  right: 60px;
  z-index: 200;
  background: rgba(0, 0, 0, 0.5);
}
</style>
<style lang="scss">
#sw-wrapper {
  height: 100%;
  width: 100%;

  #sw-container {
    height: 100%;
    width: 100%;
  }
}
</style>