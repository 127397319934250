<template>
  <v-row class="pa-0 fill-height" :style="{ 'min-height': '450px' }" no-gutters>
    <v-col v-on:keydown.83="onPressS" v-on:keyup.83="onReleaseS" v-on:keydown.69="onPressE" v-on:keyup.69="onReleaseE"
      id="map" :class="{ splitted: splitted || proofMode }" cols="12" class="pa-0 ma-0">
      <div id="mapbox-wrapper" />
    </v-col>
    <div class="toggleBdOrtho">
      <v-switch dark dense hide-details class="ma-2 white--text" label="Use BDOrtho" :input-value="useBdOrtho"
        @change="toggleBDOrtho" />
    </div>
  </v-row>
</template>
<script>
const swImg = require(`@/assets/images/sw-orientation.png`)
const proofImg = require(`@/assets/images/proof-marker.png`)
import { mapGetters, mapMutations } from 'vuex'
import { concat, findIndex, get } from 'lodash-es'
import clientApi from '@/api/clientApi'
import promisify from 'map-promisified'
import Mapbox from 'mapbox-gl'
import mask from '@turf/mask'
export default {
  name: 'TSMApWidget',
  props: {
    ad: {
      type: Object,
      required: true,
    },
    splitted: {
      type: Boolean,
      default: false,
    },
    proofMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      lastZoom: 8,
      mapbox: null,
      emptyFeatures: { type: 'FeatureCollection', features: [] },
      isSPressed: false,
      isEPressed: false,
      adSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      matchesSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      matchesSelectedSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      swSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      proofSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      useBdOrtho: false,
      bdOrtho: {
        type: 'raster',
        tiles: [
          'https://data.geopf.fr/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}',
        ],
        tileSize: 256,
        attribution: 'bdOrtho',
      },
      bdOrthoTiles: {
        id: 'bdOrthoTiles',
        type: 'raster',
        source: 'bdOrtho',
        minzoom: 0,
        layout: {
          visibility: this.useBdOrtho ? 'visible' : 'none',
        },
      },
      swLayer: {
        id: 'swLayer',
        type: 'symbol',
        source: 'sw',
        filter: ['==', '$type', 'Point'],
        layout: {
          'icon-image': 'sw-orientation',
          'icon-size': 0.2,
          'icon-rotate': 0,
        },
      },
      proofLayer: {
        id: 'proofLayer',
        type: 'symbol',
        source: 'proof',
        filter: ['==', '$type', 'Point'],
        layout: {
          'icon-image': 'proof-marker',
          'icon-size': 0.2,
          'icon-rotate': 0,
        },
      },
      hintPolygon: {
        id: 'hintPolygon',
        type: 'fill',
        source: 'ad',
        paint: {
          'fill-color': 'rgba(0, 188, 212, 0.1)',
          'fill-outline-color': '#83F2BE',
        },
      },
      hintStroke: {
        id: 'hintStroke',
        type: 'line',
        source: 'ad',
        paint: {
          'line-width': 2,
          'line-color': '#83F2BE',
        },
      },
      hintPoint: {
        id: 'hintPoint',
        type: 'circle',
        source: 'ad',
        paint: {
          'circle-radius': 5,
          'circle-color': '#83F2BE',
          'circle-stroke-width': 2,
          'circle-stroke-color': 'rgba(255,255,255,1)',
        },
        filter: ['==', '$type', 'Point'],
      },
      matchesPolygon: {
        id: 'matchesPolygon',
        type: 'fill',
        source: 'matches',
        paint: {
          'fill-color': 'rgba(0, 188, 212, 0.05)',
          'fill-outline-color': '#83F2BE',
        },
        filter: ['all', ['!=', '$type', 'Point'], ['==', 'rejected', false]],
      },
      matchesStroke: {
        id: 'matchesStroke',
        type: 'line',
        source: 'matches',
        filter: ['all', ['!=', '$type', 'Point'], ['==', 'rejected', false]],
        paint: {
          'line-width': 4,
          'line-blur': 1,
          'line-color': 'red',
        },
      },
      matchesSelectedStroke: {
        id: 'matchesSelectedStroke',
        type: 'line',
        source: 'matchesSelected',
        filter: ['all', ['!=', '$type', 'Point'], ['==', 'rejected', false]],
        paint: {
          'line-width': 6,
          'line-blur': 1,
          'line-color': '#83F2BE',
        },
      },
      matchesScoreLayer: {
        id: 'matchesScoreLayer',
        type: 'symbol',
        source: 'matches',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'rejected', false]],
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': 'rgba(0, 0, 0, 0.5)',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        },
        minzoom: 12,
        layout: {
          'text-field': ['get', 'score'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      },
      matchesSelectedLayer: {
        id: 'matchesSelectedLayer',
        type: 'symbol',
        source: 'matchesSelected',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'rejected', false]],
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': 'rgba(0, 0, 0, 0.5)',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        },
        minzoom: 12,
        layout: {
          'text-field': ['get', 'index'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 24,
          visibility: 'none',
        },
      },
      showSelection: false,
      streetViewBuffer: {},
    }
  },
  mounted() {
    const mapContainer = document.querySelector('#mapbox-container');
    const mapContainerDest = document.querySelector('#mapbox-wrapper');
    mapContainerDest.appendChild(mapContainer);


    if (this.$root.map && this.$root.map.loaded()) {
      //this.$root.map.setStyle(this.mapStyle)
      this.setLayers(this.$root.map)
      this.onLoad({ map: this.$root.map })
    } else {
      this.$root.$on('mapboxReady', async () => {
        //this.$root.map.setStyle(this.mapStyle)
        this.setLayers(this.$root.map)
        this.onLoad({ map: this.$root.map })
      })
    }

    this.$root.$on('resizeMap', this.resize)
    this.$root.$on('setPovSV', this.setPovSV)
    this.$root.$on('setPositionSV', this.setPositionSV)
  },
  destroyed() {
    this.$root.$off('resizeMap', this.resize)
    this.$root.$off('setPovSV', this.setPovSV)
    this.$root.$off('setPositionSV', this.setPositionSV)

    this.$root.map.off('click', this.onMapClick)
    this.$root.map.off('zooomend', this.onZoomEnd)
    this.$root.map.off('click', this.matchesScoreLayer.id, this.onMatchClick)
    this.$root.map.off('mouseenter', this.matchesScoreLayer.id, this.onMatchEnter)
    this.$root.map.off('mouseleave', this.matchesScoreLayer.id, this.onMatchLeave)
    this.$root.map.off('click', this.matchesSelectedLayer.id, this.onMatchClick)
    this.$root.map.off('mouseenter', this.matchesSelectedLayer.id, this.onMatchEnter)
    this.$root.map.off('mouseleave', this.matchesSelectedLayer.id, this.onMatchLeave)

    // remove Layers
    const layers = [
      this.bdOrthoTiles.id,
      this.hintPoint.id,
      this.hintStroke.id,
      this.hintPolygon.id,
      this.matchesPolygon.id,
      this.matchesStroke.id,
      this.matchesScoreLayer.id,
      this.matchesSelectedStroke.id,
      this.matchesSelectedLayer.id,
      this.swLayer.id,
      this.proofLayer.id,
    ]

    layers.forEach(l => {
      if (this.$root.map.getLayer(l)) {
        this.$root.map.removeLayer(l)
      }
    })
    // remove sources
    const sources = ['bdOrtho', 'ad', 'matches', 'matchesSelected', 'sw', 'proof']
    sources.forEach(s => {
      if (this.$root.map.getSource(s)) {
        this.$root.map.removeSource(s)
      }
    })
  },
  watch: {
    ad: function (newAd) {
      this.loadFeatures(this.$root.map, newAd)
    },
    matches: {
      handler: function (newMatches) {
        this.loadMatchFeatures(this.$root.map, newMatches)
      },
    },
    selectedMatches: {
      handler: async function (newMatches) {
        if (newMatches && newMatches.length) {
          this.loadMatchFeatures(
            this.$root.map,
            newMatches,
            'matchesSelected'
          )
          let mergedArray = []
          newMatches.forEach((m) => {
            mergedArray = concat(mergedArray, m.geom.coordinates)
          })
          await this.fitToBounds(mergedArray)
          this.showSelection = !this.disableMultiSelection
        } else {
          this.loadMatchFeatures(this.$root.map, [], 'matchesSelected')
          let mergedArray = []
          this.matches.forEach((m) => {
            mergedArray = concat(mergedArray, m.geom.coordinates)
          })
          await this.fitToBounds(mergedArray)
          this.showSelection = false
        }

        this.$root.map.setLayoutProperty(
          'matchesSelectedLayer',
          'visibility',
          this.showSelection ? 'visible' : 'none'
        )
      },
    },
    async satelliteMarkers(markers) {
      const map = this.$root.map
      if (!map) return
      const src = map.getSource('proof')
      if (!src) return

      if (markers && markers.length) {
        const { lat, lng } = markers[0]
        src.setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
            },
          ],
        })

        const zoom = this.currentProof.satelliteZoom || null
        this.toggleBDOrtho(this.currentProof.useBDOrtho)

        await this.flyTo([lng, lat], zoom)
      } else {
        src.setData(this.emptyFeatures)
      }
    },
  },
  computed: {
    ...mapGetters({
      streetViewData: 'matches/streetViewData',
      selectedMatches: 'matches/selectedMatches',
      currentFlux: 'flux/currentFlux',
      currentProof: 'matches/currentProof',
    }),
    mapStyle() {
      if (this.ad && this.ad.state === 'validated') {
        return 'mapbox://styles/mapbox/streets-v12'
      } else {
        return process.env.VUE_APP_MB_STYLE
      }
    },
    center() {
      return get(this.ad, 'geomCenter.coordinates')
    },
    geomType() {
      return get(this.ad, 'geomType')
    },
    hintGeoJson() {
      const geom = get(this.ad, 'geom')
      if (geom) {
        const feature =
          this.ad.geomType === 'exact'
            ? { type: 'Feature', geometry: geom }
            : mask({ type: 'Feature', geometry: geom })
        return {
          type: 'FeatureCollection',
          features: [feature],
        }
      } else {
        return { type: 'FeatureCollection', features: [] }
      }
    },
    matches() {
      return this.ad.matches
    },
    fluxOptions() {
      return get(this, 'currentFlux.settings.options') || {}
    },
    disableMultiSelection() {
      return get(this, 'currentFlux.settings.options.disableMultiSelection')
    },
    satelliteMarkers() {
      return this.currentProof && this.currentProof.satelliteMarkers
        ? this.currentProof.satelliteMarkers
        : []
    },
    role() {
      return get(this.user, 'customData.role')
    },
  },
  methods: {
    ...mapMutations({
      setCurrentMatch: 'matches/SET_CURRENT_MATCH',
      setProofProperty: 'matches/SET_PROOF_PROPERTY',
    }),
    setLayers(map) {
      map.resize()
      map.setZoom(10)
      map.setCenter(this.center)
      map.on('click', this.onMapClick)
      map.on('zoomend', this.onZoomEnd)

      // sources
      map.addSource('bdOrtho', this.bdOrtho)
      map.addSource('ad', this.adSource)
      map.addSource('matches', this.matchesSource)
      map.addSource('matchesSelected', this.matchesSelectedSource)
      map.addSource('sw', this.swSource)
      map.addSource('proof', this.proofSource)

      // layers
      map.addLayer(this.bdOrthoTiles)
      map.addLayer(this.hintPoint)
      map.addLayer(this.hintStroke)
      map.addLayer(this.hintPolygon)
      map.addLayer(this.matchesPolygon)
      map.addLayer(this.matchesStroke)
      map.addLayer(this.matchesScoreLayer)
      map.addLayer(this.matchesSelectedStroke)
      map.addLayer(this.matchesSelectedLayer)
      map.addLayer(this.swLayer)
      map.addLayer(this.proofLayer)

      map.on('click', this.matchesScoreLayer.id, this.onMatchClick)
      map.on('mouseenter', this.matchesScoreLayer.id, this.onMatchEnter)
      map.on('mouseleave', this.matchesScoreLayer.id, this.onMatchLeave)

      map.on('click', this.matchesSelectedLayer.id, this.onMatchClick)
      map.on('mouseenter', this.matchesSelectedLayer.id, this.onMatchEnter)
      map.on('mouseleave', this.matchesSelectedLayer.id, this.onMatchLeave)

    },
    async flyTo(coordinates, z) {
      const flyToPromisified = promisify(this.$root.map, 'flyTo')
      const zoom = z || this.$root.map.getZoom()
      const config = {
        center: coordinates,
        zoom,
      }

      if (this.fluxOptions.disableAnimation) {
        config.duration = 0
      } else {
        config.speed = 3
      }

      await flyToPromisified(config)
    },
    async fitToBounds(coordinates) {
      let bounds = new Mapbox.LngLatBounds()

      coordinates.forEach((array) => {
        array.forEach((coord) => {
          bounds.extend(coord)
        })
      })

      const fitToPromisified = promisify(this.$root.map, 'fitBounds')

      const config = {
        padding: 40,
      }

      if (this.fluxOptions.disableAnimation) {
        config.duration = 0
      } else {
        config.speed = 3
      }

      await fitToPromisified(bounds, config)
    },
    async loadFeatures(map, ad) {
      const t = get(ad, 'geomType')
      if (t === 'cityOrArrondissement') {
        const { town } = await clientApi.get(
          `boundaries/towns/${this.ad.codeInsee}`
        )
        const feature = {
          type: 'Feature',
          geometry: town.geom,
        }

        map.getSource('ad').setData({
          type: 'FeatureCollection',
          features: [mask(feature)],
        })
        const geomCoords = town.geom.coordinates[0] // potential bug
        await this.fitToBounds(geomCoords)
      } else if (t === 'disk') {
        const geomCoords = get(this.ad, 'geom').coordinates // potential bug
        await this.fitToBounds(geomCoords)
        map.getSource('ad').setData(this.hintGeoJson)
      } else if (t === 'exact') {
        await this.flyTo(this.center, 18)
        map.getSource('ad').setData(this.hintGeoJson)
      }

      if (
        this.currentProof &&
        this.currentProof.satelliteMarkers &&
        this.currentProof.satelliteMarkers.length
      ) {
        const { lat, lng } = this.currentProof.satelliteMarkers[0]
        map.getSource('proof').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
            },
          ],
        })
        const zoom = this.currentProof.satelliteZoom || null
        await this.flyTo([lng, lat], zoom)
      }

      this.moveLayers(map)
    },
    loadMatchFeatures(map, matches, source = 'matches') {
      const features = []

      matches.forEach((m, i) => {
        features.push({
          type: 'Feature',
          geometry: m.geom,
          properties: {
            rejected: m.rejected,
          },
        })
        features.push({
          id: m.id,
          type: 'Feature',
          geometry: m.geomCenter,
          properties: {
            index: i + 1,
            score: m.score ? m.score.toFixed(2) : 0,
            rejected: m.rejected,
          },
        })
      })

      map.getSource(source).setData({
        type: 'FeatureCollection',
        features,
      })
    },
    moveLayers(map) {
      map.moveLayer('bdOrthoTiles', 'tunnel-major-link-case')
      const layerOrder = ['hintPolygon', 'hintStroke', 'hintPoint']
      layerOrder.forEach((l) => {
        if (map.getLayer(l)) {
          map.moveLayer(l)
        }
      })
    },
    async onLoad({ map }) {
      // store the map as a non reactive object - https://github.com/phegman/vue-mapbox-gl/issues/58
      if (!map.hasImage('sw-orientation')) {
        map.loadImage(swImg, (error, image) => {
          if (error) throw error
          // add image to the active style and make it SDF-enabled
          map.addImage('sw-orientation', image, { sdf: false })
        })
      }

      if (!map.hasImage('proof-marker')) {
        map.loadImage(proofImg, (error, image) => {
          if (error) throw error
          // add image to the active style and make it SDF-enabled

          map.addImage('proof-marker', image, { sdf: false })
        })
      }

      await this.loadFeatures(map, this.ad)
      await this.loadMatchFeatures(map, this.matches)
      await this.loadMatchFeatures(map, this.selectedMatches, 'matchesSelected')

      if (this.currentProof && this.currentProof.useBDOrtho) {
        this.toggleBDOrtho()
      }

      if (this.streetViewData) {
        this.setPositionSV(this.streetViewData.position)
        this.setPovSV(this.streetViewData.pov)
      }

      if (this.mapStyle === 'mapbox://styles/mapbox/streets-v11') {
        this.$root.map.setLayoutProperty(
          'building-number-label',
          'text-size',
          18
        )
        this.$root.map.setLayoutProperty(
          'building-number-label',
          'text-font',
          ['DIN Offc Pro Medium', 'Arial Unicode MS Bold']
        )

        this.$root.map.setPaintProperty(
          'building-number-label',
          'text-color',
          '#14617E'
        )
      }

      this.$emit('loaded')
    },
    onPressS() {
      this.isSPressed = true
      this.setCursor(this.$root.map, 'crosshair')
    },
    onReleaseS() {
      this.isSPressed = false
      this.setCursor(this.$root.map, '')
    },
    onPressE() {
      if (this.proofMode && this.role === 'OPERATOR') {
        this.isEPressed = true
        this.setCursor(this.$root.map, 'crosshair')
      }
    },
    onReleaseE() {
      if (this.proofMode && this.role === 'OPERATOR') {
        this.isEPressed = false
        this.setCursor(this.$root.map, '')
      }
    },
    onMapClick({ lngLat }) {
      if (this.isSPressed) {
        this.$root.$emit('forceStreetView', lngLat.lat, lngLat.lng)
      }

      if (this.isEPressed && this.role === 'OPERATOR') {
        this.setProofProperty({
          key: 'satelliteMarkers',
          value: [{ lng: lngLat.lng, lat: lngLat.lat }],
        })
      }
    },
    async onMatchClick({ features }) {
      const feature = features[features.length - 1]
      await this.flyTo(feature.geometry.coordinates)
      const index = findIndex(this.matches, (m) => {
        return m.id === feature.id
      })
      if (this.disableMultiSelection) {
        this.setCurrentMatch(this.matches[index])
      }
    },
    onMatchEnter({ target }) {
      this.setCursor(target, 'pointer')
    },
    onMatchLeave({ target }) {
      this.setCursor(target, '')
    },
    onZoomEnd() {
      if (this.proofMode && this.role === 'OPERATOR') {
        this.setProofProperty({
          key: 'satelliteZoom',
          value: this.$root.map.getZoom(),
        })
      }
    },
    resize() {
      setTimeout(() => {
        this.$root.map.resize()
      }, 100)
    },
    setCursor(map, value) {
      map.getCanvas().style.cursor = value
    },
    setPovSV(pov) {
      const map = this.$root.map
      if (!map) return
      map.setLayoutProperty('swLayer', 'icon-rotate', pov.heading)
    },
    async setPositionSV({ lat, lng }) {
      const map = this.$root.map
      if (!map) return
      const src = map.getSource('sw')
      if (!src) return

      src.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lng, lat],
            },
          },
        ],
      })
      if (this.proofMode && this.role !== 'VALIDATOR') {
        await this.flyTo([lng, lat], 17)
      }
    },
    toggleBDOrtho(v = null) {
      if (v !== null) {
        this.useBdOrtho = v
      } else {
        this.useBdOrtho = !this.useBdOrtho
      }

      this.$root.map.setLayoutProperty(
        'bdOrthoTiles',
        'visibility',
        this.useBdOrtho ? 'visible' : 'none'
      )
      this.moveLayers(this.$root.map)
      if (this.proofMode && this.role === 'OPERATOR') {
        this.setProofProperty({ key: 'useBDOrtho', value: this.useBdOrtho })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#map {
  position: absolute;
  top: 48px;
  bottom: 0px;

  &.splitted {
    top: 0;
  }
}

.toggleBdOrtho {
  position: absolute;
  padding-right: 0;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 0;
}

#mapbox-wrapper {
  height: 100%;
  width: 100%;

  #mapbox-container {
    height: 100%;
    width: 100%;
  }
}
</style>
