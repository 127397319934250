<template>
  <div>
    <v-row class="fill-height" v-if="loading">
      <v-col cols="12" class="d-flex">
        <v-progress-linear indeterminate color="white" />
      </v-col>
    </v-row>
    <div v-else-if="msg !== ''">
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">
          <span class="f-title">{{ msg }}</span>
        </div>
      </v-col>
    </div>
    <div v-else>
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div v-if="currentFlux" class="white--text">
          <span class="f-title">{{ currentFlux.name }}</span><br />
          <span class="f-title">
            <span v-if="role === 'VALIDATOR'">Items à traiter : {{ treated }}</span>
            <v-btn v-if="showProofModeBtn" @click="
              onProofValidationMode(
                fluxItem.matches[0],
                fluxItem.matches[0].customData.proofs
              )
              " color="orange" class="text-none ml-3" small dark>
              MODE PREUVE
            </v-btn>
          </span>
        </div>
        <v-spacer />
        <v-btn v-if="showNextBtn" @click="skip" color="orange" class="text-none" dark>
          <v-icon small left> mdi-alert </v-icon>
          Passer au suivant
        </v-btn>
      </v-col>
      <div v-if="proofMode" class="proof-instruction elevation-1 pa-0 primary">
        <div v-if="role === 'OPERATOR'">
          <span class="overline white--text">Vous devez fournir une ou plusieurs preuves pour pouvoir valider
            votre choix.</span>
          <v-btn @click="onProofMode(false)" color="orange" class="text-none ml-3" dark>
            Annuler
          </v-btn>
          <v-btn @click="validateProofs" class="text-none ml-3" :disabled="trustScore < 2">
            {{ validateText }}
          </v-btn>
        </div>
        <v-row no-gutters class="pa-4" v-else>
          <span class="overline white--text" v-if="role === 'VALIDATOR'">Items à traiter : {{ treated }}</span>
          <v-spacer />
          <v-btn @click="onProofMode(false)" color="orange" class="text-none ml-3" small dark>
            QUITTER LE MODE PREUVE
          </v-btn>
          <v-btn color="error" class="text-none ml-3" small dark @click="rejectMatch">
            REJETER
          </v-btn>
          <v-btn color="secondary" class="text-none ml-3" small @click="validateMatch">
            VALIDER
          </v-btn>
        </v-row>
      </div>
      <draggable :list="widgets" handle=".handle" group="widgets" class="row px-3">
        <t-s-widget v-for="w in widgets" v-bind="w" :fluxItem="fluxItem" :key="w.id" :isSplit="split"
          :isProofMode="proofMode" @refresh="onRefresh" @split="onSplit" @loaded="onLoaded" />
      </draggable>
      <v-btn v-show="split" id="unsplit-btn" color="orange" dark @click="onSplit(false)" fab
        transition="scale-transition">
        <v-icon>mdi-arrow-collapse-vertical</v-icon>
      </v-btn>
    </div>
    <t-s-photo-gallery />
    <div v-if="proofMode">
      <v-row class="proof-container" no-gutters>
        <v-col cols="12" v-if="role === 'OPERATOR'" class="proof-form pa-3">
          <p class="overline">3 - Indice de confiance</p>
          <v-radio-group v-model="trust" dense>
            <v-radio label="Faible" :value="1" />
            <v-radio label="Fort" :value="2" />
          </v-radio-group>
          <p class="overline mb-0">4 - Commentaire (optionnel)</p>
          <v-textarea v-model="comment" class="pt-0 mt-0" rows="3" />
          <v-btn @click="addProof" block>Ajouter la preuve</v-btn>
          <div class="proof-form-mask" :class="{ disabled: useSatellite || useStreetView }" />
        </v-col>
        <v-col v-if="proofs.length" cols="12" class="proof-list">
          <v-list two-line dense>
            <v-list-item v-for="(p, i) in proofs" :key="`p-${i}`" @click="onProofClick(p)">
              <template v-slot:default="{ }">
                <v-list-item-avatar tile>
                  <v-img :src="photos[p.photos[0]]" aspect-ratio="1" class="grey lighten-2" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b class="primary--text">{{ proofTitle(p) }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle class="full">
                    <small v-html="proofSubtitle(p)" />
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="role === 'OPERATOR'">
                  <v-btn small icon>
                    <v-icon small color="red lighten-1" @click.stop="removeProof(i)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <div v-if="role === 'OPERATOR'" class="map-proof-mask" :class="{ disabled: useSatellite || useStreetView }">
        <v-checkbox v-if="proofMode && selectedPhotos.length && !useStreetView" v-model="useSatellite"
          @click.native="$event.stopPropagation()" class="ml-2 mb-1 check" hide-details dense dark>
          <template v-slot:label>
            <small class="overline">
              <b v-if="!useSatellite" class="white--text">2 - La photo choisie à l'étape 1 est à comparer avec la vue
                satellite</b>
              <span v-else class="primary--text notice overline">Pour cibler un élément reconnaissable entre la photo et
                la vue
                satellite: maintenez E + CLIC
              </span>
            </small>
          </template>
        </v-checkbox>
      </div>
      <div v-if="role === 'OPERATOR'" class="sv-proof-mask" :class="{
        disabled: useSatellite || useStreetView,
        selected: useStreetView,
      }">
        <v-checkbox v-if="proofMode && selectedPhotos.length && !useSatellite" v-model="useStreetView"
          @click.native="$event.stopPropagation()" class="ml-2 mb-1 check" hide-details dense :dark="!useStreetView">
          <template v-slot:label>
            <small class="overline">
              <b v-if="!useStreetView" class="white--text">2 - OU La photo choisie à l'étape 1 est à comparer avec le
                street view</b>
              <b v-else class="primary--text notice overline">Merci de bien positionner la caméra en fonction de la
                photo
                choisie</b>
            </small>
          </template>
        </v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { find, isEmpty, get, reduce } from 'lodash-es'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'
import draggable from 'vuedraggable'
import TSPhotoGallery from '@/components/common/TSPhotoGallery'
import TSWidget from '@/components/flux/widgets/TSWidget'
export default {
  name: 'FluxWork',
  components: {
    draggable,
    TSPhotoGallery,
    TSWidget,
  },
  mixins: [TSCommonMixin],
  data: () => {
    let swProofStyle = {
      top: 'calc(50vh + 30px)',
      right: '320px',
      left: '240px',
      height: 'calc(50vh - 30px)',
    }
    let mapProofStyle = {
      top: '60px',
      right: '320px',
      bottom: 0,
      left: '240px',
      height: 'calc(50vh - 30px)',
    }
    return {
      loading: true,
      split: false,
      proofMatch: null,
      proofMode: false,
      isMapboxReady: false,
      isStreetViewReady: false,
      widgets: [
        {
          id: 'a',
          title: 'Annonce',
          type: 'ad',
          cols: 3,
          isExpandable: false,
          splitStyle: {},
          proofStyle: {
            display: 'none !important',
          },
        },
        {
          id: 'c',
          title: 'Carte',
          type: 'map',
          cols: 6,
          splitStyle: {
            top: 0,
            right: '240px',
            bottom: 0,
            left: '120px',
            height: '45vh',
          },
          proofStyle: { ...mapProofStyle },
        },
        {
          id: 'm',
          title: 'Candidats',
          type: 'matches',
          cols: 3,
          isExpandable: false,
          isRefreshable: true,
          splitStyle: {
            top: '0px',
            width: '240px',
            bottom: '0',
            right: 0,
          },
          proofStyle: {
            display: 'none !important',
          },
        },
        {
          id: 'g',
          title: 'Gallerie',
          type: 'gallery',
          cols: 3,
          columns: 4,
          splitStyle: {
            top: 0,
            width: '120px',
            bottom: 0,
            left: 0,
          },
          proofStyle: {
            top: '60px',
            width: '240px',
            height: '90vh',
            left: 0,
          },
        },
        {
          id: 'sw',
          title: 'Street View',
          type: 'streetView',
          cols: 6,
          splitStyle: {
            top: '45vh',
            right: '240px',
            bottom: 0,
            left: '120px',
            height: '55vh',
          },
          proofStyle: { ...swProofStyle },
        },
      ],
      mapProofStyle,
      swProofStyle,
      startDate: null,
    }
  },
  created() {
    this.load(this.$route.params.id, this.$route.params.itemId)
    this.$root.$on('next', this.onNext)
    this.$root.$on('proofMode', this.onProofMode)
  },
  destroyed() {
    this.setCurrentFlux(null)
    this.$root.$off('next', this.onNext)
    this.$root.$off('proofMode', this.onProofMode)
  },
  watch: {
    $route(to) {
      this.load(to.params.id, to.params.itemId)
    },
    selectedPhotos(newVal, oldVal) {
      // if selection on photo is cancel then we reset the current proof
      if (oldVal && newVal && oldVal.length > 0 && newVal.length === 0) {
        this.resetProof()
        this.overrideWidgetProofStyle('sw', this.swProofStyle)
        this.overrideWidgetProofStyle('c', this.mapProofStyle)
        this.$root.$emit('resizeMap')
      }
    },
    useSatellite(newVal) {
      if (newVal) {
        this.overrideWidgetProofStyle('c', {
          ...this.mapProofStyle,
          ...{
            height: 'calc(100vh - 60px)',
          },
        })
        this.overrideWidgetProofStyle('sw', {
          ...this.swProofStyle,
          ...{
            top: '-100vh',
            height: 'calc(100vh - 60px)',
          },
        })
      } else {
        this.overrideWidgetProofStyle('sw', this.swProofStyle)
        this.overrideWidgetProofStyle('c', this.mapProofStyle)
      }

      this.$root.$emit('resizeMap')
    },
    useStreetView(newVal) {
      if (newVal) {
        this.overrideWidgetProofStyle('sw', {
          ...this.swProofStyle,
          ...{
            top: 'calc(35vh + 30px)',
            height: 'calc(65vh - 30px)',
          },
        })
        this.overrideWidgetProofStyle('c', {
          ...this.mapProofStyle,
          ...{
            height: 'calc(35vh - 30px)',
          },
        })
      } else if (this.role === 'OPERATOR') {
        this.overrideWidgetProofStyle('sw', this.swProofStyle)
        this.overrideWidgetProofStyle('c', this.mapProofStyle)
      }

      this.$root.$emit('resizeMap')
    },
  },
  computed: {
    ...mapGetters({
      currentFlux: 'flux/currentFlux',
      fluxItem: 'fluxItems/currentFluxItem',
      fluxStats: 'stats/fluxStats',
      currentProof: 'matches/currentProof',
      proofs: 'matches/proofs',
    }),
    isReady() {
      return this.isMapboxReady && this.isStreetViewReady
    },
    treated() {
      return this.currentFlux && this.fluxStats[this.currentFlux.id]
        ? this.fluxStats[this.currentFlux.id].treated
        : 0
    },
    showNextBtn() {
      return this.currentFlux && this.role === 'OPERATOR'
    },
    selectedPhotos() {
      return this.currentProof && this.currentProof.photos
        ? this.currentProof.photos
        : []
    },
    useStreetView: {
      get() {
        return this.currentProof.compareWithStreetView
      },
      set(newVal) {
        this.setProofProperty({ key: 'compareWithStreetView', value: newVal })
      },
    },
    useSatellite: {
      get() {
        return this.currentProof.compareWithSatelliteView
      },
      set(newVal) {
        this.setProofProperty({
          key: 'compareWithSatelliteView',
          value: newVal,
        })
      },
    },
    validateText() {
      switch (this.trustScore) {
        case 1:
          return 'Ajouter une autre preuve pour pouvoir valider'
        default:
          return 'Valider'
      }
    },
    comment: {
      get() {
        return this.currentProof.comment
      },
      set(newVal) {
        this.setProofProperty({ key: 'comment', value: newVal })
      },
    },
    showProofModeBtn() {
      const match = this.fluxItem.matches[0]
      const proofs = get(match, 'customData.proofs')
      return (
        ['VALIDATOR', 'ADMIN'].includes(this.role) && proofs && proofs.length
      )
    },
    trust: {
      get() {
        return this.currentProof.trust
      },
      set(newVal) {
        this.setProofProperty({ key: 'trust', value: newVal })
      },
    },
    trustScore() {
      return reduce(
        this.proofs,
        (sum, obj) => {
          sum += obj.trust
          return sum
        },
        0
      )
    },
  },
  methods: {
    ...mapActions({
      getFluxItem: 'fluxItems/getFluxItem',
      getFluxItemMatches: 'fluxItems/getFluxItemMatches',
      getFluxItemPicturesClasses: 'fluxItems/getFluxItemPicturesClasses',
      updateFluxItem: 'fluxItems/updateFluxItem',
      getFluxDetails: 'flux/getFluxDetails',
      resetFluxDetails: 'flux/resetDetails',
      createMatch: 'matches/createMatch',
      updateMatch: 'matches/updateMatch',
      getFluxStats: 'stats/getFluxStats',
    }),
    ...mapMutations({
      setCurrentFlux: 'flux/SET_CURRENT_FLUX',
      setCurrentMatch: 'matches/SET_CURRENT_MATCH',
      setStreetViewData: 'matches/SET_STREET_VIEW_DATA',
      setProofProperty: 'matches/SET_PROOF_PROPERTY',
      addProof: 'matches/ADD_PROOF',
      setCurrentProof: 'matches/SET_CURRENT_PROOF',
      setProofs: 'matches/SET_PROOFS',
      removeProof: 'matches/REMOVE_PROOF',
      resetProof: 'matches/RESET_PROOF',
      resetProofs: 'matches/RESET_PROOFS',
    }),
    async loadItem(fluxId, fluxItemId) {
      this.setStreetViewData(null)
      const params = fluxItemId ? { fluxItemId } : { fluxId }
      await this.getFluxItem({ params })

      if (fluxId) {
        this.getFluxStats({ fluxId, params: { state: this.role === 'VALIDATOR' ? 'treated' : 'created' } })
      }

      if (this.fluxItem) {
        await this.getFluxItemMatches({ id: this.fluxItem.id })
        this.getFluxItemPicturesClasses({ id: this.fluxItem.id })
        // enter in validation proof mode ?
        if (this.role === 'VALIDATOR' && this.fluxItem.state === 'treated') {
          const match = this.fluxItem.matches[0]
          const proofs = get(match, 'customData.proofs')
          if (proofs && proofs.length) {
            this.onProofValidationMode(this.fluxItem.matches[0], proofs)
          }
        }
      } else {
        this.msg = 'Aucun item à traiter pour ce flux ...'
      }
      this.loading = false
      this.startDate = new Date()
    },
    async load(fluxId, itemId) {
      this.msg = ''
      this.loading = true
      await this.getFluxDetails({ id: fluxId })
      if (itemId) {
        this.loadItem(null, itemId)
      } else {
        this.loadItem(fluxId)
      }
    },
    async next(state, persist = true) {
      this.msg = ''
      this.loading = true

      //move back sw
      const swContainer = document.querySelector('#sw-container');
      const swContainerDest = document.querySelector('#sw-original-wrapper');
      swContainerDest.appendChild(swContainer);

      // move back mapbox
      const mapContainer = document.querySelector('#mapbox-container')
      const mapContainerDest = document.querySelector('#mapbox-original-wrapper')
      if (mapContainerDest && mapContainer) {
        mapContainerDest.appendChild(mapContainer)
      }

      if (persist) {
        const fluxItem = {
          id: this.fluxItem.id,
          state,
        }

        const endDate = new Date()
        const seconds = Math.round(
          (endDate.getTime() - this.startDate.getTime()) / 1000
        )

        if (state === 'skipped' || state === 'treated') {
          fluxItem.treatedOrSkippedBy = this.currentUser.id
          fluxItem.operatorTimeSpent = seconds
        } else if (state === 'validated' || state === 'rejected') {
          fluxItem.validatedOrRejectedBy = this.currentUser.id
          fluxItem.validatorTimeSpent = seconds
        }

        await this.updateFluxItem({ fluxItem })
      }

      if (this.$route.params.itemId) {
        this.$router.push({
          name: 'FluxBrowser',
          params: { id: this.$route.params.id },
        })
      } else {
        this.loadItem(this.$route.params.id)
      }
    },
    onLoaded(type) {
      if (type === 'map') {
        this.isMapboxReady = true
      } else if (type === 'streetView') {
        this.isStreetViewReady = true
      }
      if (this.isReady && this.fluxItem.matches.length) {
        this.setCurrentMatch(this.fluxItem.matches[0])
      }
    },
    onNext(state, persist = true) {
      this.next(state, persist)
    },
    async onRefresh(type) {
      if (type === 'matches' && this.role !== 'VALIDATOR') {
        await this.getFluxItemMatches({ id: this.fluxItem.id, recompute: true })
      } else {
        alert(
          'Vous ne pouvez pas rafraichir cette liste en tant que validateur'
        )
      }
    },
    overrideWidgetProofStyle(id, proofStyle) {
      const widget = find(this.widgets, (w) => {
        return w.id === id
      })
      if (!widget) return

      widget.proofStyle = proofStyle
    },
    onProofClick(proof) {
      if (this.role === 'VALIDATOR') {
        this.setCurrentProof(proof)
      }
    },
    onProofMode(v, match = null) {
      this.proofMode = v
      this.proofMatch = match
      if (!v) {
        this.resetProof()
        this.resetProofs()
        this.overrideWidgetProofStyle('sw', this.swProofStyle)
        this.overrideWidgetProofStyle('c', this.mapProofStyle)
        this.$root.$emit('resizeMap')
      }
    },
    onProofValidationMode(match, proofs) {
      this.proofMode = true
      this.proofMatch = match
      this.setCurrentProof(proofs[0])
      this.setProofs(proofs)
    },
    onSplit(v) {
      this.split = v
    },
    proofTitle(p) {
      if (!p) return ''
      return `Avec ${p.compareWithStreetView ? 'StreetView' : 'vue Satellite'
        } : ${p.trust === 1 ? 'Faible' : 'Fort'}`
    },
    proofSubtitle(p) {
      if (!p) return ''
      /* eslint-disable no-useless-escape */
      const regUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[-+=&;%@,/!.\w_]*)#?(?:[\.\!\/\\\w]*))?)/gm
      return isEmpty(p.comment)
        ? 'Sans commentaire'
        : p.comment.replaceAll(regUrl, '<a target="_blank" href="$&"> $& </a>')
    },
    skip() {
      this.next('skipped')
    },
    async rejectMatch() {
      await this.updateMatch({
        id: this.proofMatch.id,
        match: {
          rejected: true,
        },
      })

      if (this.fluxItem.state === 'treated') {
        this.next('rejected')
      }
    },
    async validateMatch() {
      await this.next('validated')
      this.onProofMode(false)
    },
    async validateProofs() {
      await this.createMatch({
        match: {
          method: 'human',
          score: 100,
          fluxItemId: this.proofMatch.fluxItemId,
          geom: this.proofMatch.geom,
          geomCenter: this.proofMatch.geomCenter,
          customData: {
            ...this.proofMatch.customData,
            ...{
              by: this.currentUser.id,
              proofs: this.proofs,
            },
          },
        },
      })
      this.next('treated')
      this.onProofMode(false)
    },
  },
}
</script>

<style lang="scss" scoped>
#unsplit-btn {
  position: fixed;
  bottom: 10px;
  left: 25px;
  z-index: 200;
}

.f-title {
  font-size: 1.5rem !important;
  display: inline-block;
  vertical-align: middle;
}

.proof-instruction {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  line-height: 60px;
  height: 60px;
  text-align: center;
}

.map-proof-mask {
  position: fixed;
  z-index: 200;
  top: 60px;
  right: 320px;
  bottom: 0;
  left: 240px;
  height: calc(50vh - 30px);
}

.sv-proof-mask {
  position: fixed;
  z-index: 200;
  top: calc(50vh + 30px);
  right: 320px;
  left: 240px;
  height: calc(50vh - 30px);

  &.selected {
    top: calc(35vh + 30px);
  }
}

.sv-proof-mask,
.map-proof-mask,
.proof-form-mask {
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;

  &.disabled {
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;

    .check {
      pointer-events: all;
    }

    .notice {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.proof-container {
  position: fixed;
  z-index: 200;
  top: 60px;
  right: 0;
  width: 320px;
  height: calc(100vh - 60px);

  .proof-list {
    overflow-y: auto;
    height: calc(50vh - 30px);
  }

  .proof-form {
    position: relative !important;

    .proof-form-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.proof-container {
  background: white;
}

.full {
  overflow: visible !important;
  white-space: normal !important;
}
</style>
