import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Dashboard from '../views/Dashboard.vue'
import Users from '../views/Users.vue'
import Flux from '../views/Flux.vue'
import FluxBrowser from '../views/FluxBrowser.vue'
import FluxDuplicate from '../views/FluxDuplicate.vue'
import FluxEdit from '../views/FluxEdit.vue'
import FluxWork from '../views/FluxWork.vue'
import Teams from '../views/Teams.vue'
import TeamBrowser from '../views/TeamBrowser.vue'
import TeamEdit from '../views/TeamEdit.vue'
import Login from '../views/Login.vue'

import { get } from 'lodash-es'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'Users',
        path: '/users',
        component: Users,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'Flux',
        path: '/flux',
        component: Flux,
      },
      {
        name: 'FluxWork',
        path: '/flux/:id',
        component: FluxWork,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'FluxDuplicate',
        path: '/flux/:id/duplicates',
        component: FluxDuplicate,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'FluxBrowserWork',
        path: '/flux/:id/browser/:itemId',
        component: FluxWork,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'FluxBrowser',
        path: '/flux/:id/browser',
        component: FluxBrowser,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'FluxDuplicateWork',
        path: '/flux/:id/duplicates/:itemId',
        component: FluxDuplicate,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'FluxEdit',
        path: '/flux/:id/edit',
        component: FluxEdit,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'Teams',
        path: '/teams',
        component: Teams,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'TeamEdit',
        path: '/teams/:id/edit',
        component: TeamEdit,
        meta: {
          adminOnly: true,
        },
      },
      {
        name: 'TeamBrowser',
        path: '/teams/:id',
        component: TeamBrowser,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true,
    },
  },
]

const router = new VueRouter({
  routes,
})

router.afterEach(async () => {
  const mapContainer = document.querySelector('#mapbox-container')
  const mapContainerDest = document.querySelector('#mapbox-original-wrapper')
  if (mapContainerDest && mapContainer) {
    mapContainerDest.appendChild(mapContainer)
  }

  const swContainer = document.querySelector('#sw-container')
  const swContainerDest = document.querySelector('#sw-original-wrapper')
  if (swContainer && swContainerDest) {
    swContainerDest.appendChild(swContainer)
  }
})

router.beforeEach(async (to, from, next) => {
  if (from.name === null) {
    await store.dispatch('auth/authMe').catch(() => {
      console.log('non authenticated user')
    })
  }

  let user = store.getters['auth/currentUser']

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user === null) {
      next({
        name: 'Login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      if (to.matched.some((record) => record.meta.adminOnly)) {
        if (get(user, 'customData.role') === 'ADMIN') {
          next()
        } else {
          next({ name: 'Main' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (user === null) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  } else {
    next()
  }
})

export default router
