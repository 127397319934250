<template>
  <div>
    <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
      <div class="white--text">
        <span class="f-title">TABLEAU DE BORD</span>
      </div>
    </v-col>
    <div>
      <draggable
        :list="widgets"
        handle=".handle"
        group="widgets"
        class="row px-3"
      >
        <t-s-widget
          v-for="w in widgets"
          v-bind="w"
          :key="w.id"
          :isSplit="split"
          :fluxItem="fluxItem"
          @refresh="onRefresh"
          @split="onSplit"
          @loaded="onLoaded"
        />
      </draggable>
      <v-btn
        v-show="split"
        id="unsplit-btn"
        color="orange"
        dark
        @click="onSplit(false)"
        fab
        transition="scale-transition"
      >
        <v-icon>mdi-arrow-collapse-vertical</v-icon>
      </v-btn>
    </div>
    <t-s-photo-gallery />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'
import draggable from 'vuedraggable'
import TSPhotoGallery from '@/components/common/TSPhotoGallery'
import TSWidget from '@/components/dashboard/TSWidget'
export default {
  name: 'Dashboard',
  components: {
    draggable,
    TSPhotoGallery,
    TSWidget,
  },
  mixins: [TSCommonMixin],
  data: () => ({
    loading: true,
    split: false,
    isMapboxReady: false,
    isStreetViewReady: false,
    widgets: [
      {
        id: 'map',
        title: 'Annonces géolocalisées',
        type: 'map',
        cols: 9,
        isExpandable: true,
        splitStyle: {
          top: 0,
          right: '340px',
          bottom: 0,
          left: 0,
          height: '100vh',
        },
      },
      {
        id: 'ad',
        title: 'Annonce',
        type: 'ad',
        cols: 3,
        isExpandable: false,
        splitStyle: {
          top: 0,
          right: 0,
          bottom: 0,
          width: '340px',
          height: '100vh',
        },
      },
      {
        id: 'charts',
        title: 'Statistiques',
        type: 'charts',
        cols: 12,
        isExpandable: true,
        splitStyle: {
          height: 0,
          width: 0,
        },
      },
    ],
  }),
  created() {},
  watch: {},
  computed: {
    ...mapGetters({
      currentFlux: 'flux/currentFlux',
      fluxItem: 'fluxItems/currentFluxItem',
    }),
  },
  methods: {
    onLoaded(type) {
      console.log(type)
    },
    onRefresh(type) {
      console.log(type)
    },
    onSplit(v) {
      this.split = v
    },
  },
}
</script>

<style lang="scss" scoped>
#unsplit-btn {
  position: fixed;
  bottom: 10px;
  left: 25px;
  z-index: 200;
}
.f-title {
  font-size: 1.5rem !important;
  display: inline-block;
  vertical-align: middle;
}
.f-desc {
  display: inline-block;
  line-height: 1.3em;
  max-width: 500px;
  font-size: 16px;
}
</style>
