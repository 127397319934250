<template>
  <v-app id="ts-main-app">
    <v-app-bar v-show="!mini" id="ts-main-appbar" elevation="1" absolute app>
      <v-app-bar-nav-icon @click="mini = !mini">
        <v-icon v-if="!mini">mdi-chevron-left</v-icon>
        <v-icon v-else>mdi-chevron-right</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer />
      <v-btn @click="logOut" icon>
        <v-icon>mdi-location-exit</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer id="ts-main-drawer" class="elevation-1" v-model="drawer" :mini-variant="mini" width="210"
      mini-variant-width="64" permanent floating app>
      <div class="py-3 px-2">
        <v-row id="ts-main-drawer-header" :class="[mini ? 'gradient' : '']">
          <v-col class="ts-main-logo" @click="mini = false">
            <v-icon :class="[mini ? 'ml-1' : 'mt-0']" :color="mini ? 'white' : 'primary'" x-large>
              mdi-map-marker-radius-outline
            </v-icon>
          </v-col>
          <v-col v-show="!mini">
            <div class="font-weight-light primary--text">Telescop Stelline</div>
            <small class="font-weight-light grey--text">{{ role }}</small>
          </v-col>
        </v-row>
      </div>
      <v-list v-for="(list, l) in drawerConfig" :key="l" dense shaped
        v-show="list.roles ? list.roles.includes(role) : true">
        <v-subheader v-if="list.subheader && !mini" class="font-weight-light ml-1">{{ list.subheader }}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in list.items" :key="i" :to="item.to" v-show="!item.disabled" exact>
            <v-list-item-icon class="mr-4">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.count !== undefined">
              <v-badge inline :content="item.count" />
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main id="ts-main-main" :class="{ 'pt-0': mini }">
      <v-container id="ts-main-container" fluid>
        <div id="mapbox-original-wrapper">
          <div id="mapbox-container" />
        </div>
        <div id="sw-original-wrapper">
          <div id="sw-container" />
        </div>
        <div v-if="showPrettyBg" class="pretty-bg elevation-1"></div>
        <router-view class="p-r z-200" />
      </v-container>
      <t-snack-bar />
      <t-s-confirm-dialog />
    </v-main>
  </v-app>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { mapActions, mapGetters } from 'vuex'
import { get, map, sortBy } from 'lodash-es'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'
import TSConfirmDialog from '@/components/common/TSConfirmDialog'
import TSnackBar from '@/components/common/TSnackBar'

export default {
  name: 'Main',
  components: { TSConfirmDialog, TSnackBar },
  mixins: [TSCommonMixin],
  props: {
    showPrettyBg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      drawer: true,
      mini: false,
      loading: {
        flux: true,
        teams: true,
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.displayLogin = true
    }, 300)
  },
  computed: {
    ...mapGetters({
      flux: 'flux/flux',
      teams: 'teams/teams',
      totalFlux: 'flux/totalFlux',
      fluxStats: 'stats/fluxStats',
    }),
    drawerConfig() {
      return [
        {
          subheader: null,
          roles: ['ADMIN'],
          items: [
            {
              title: 'Tableau de bord',
              to: { name: 'Dashboard' },
              icon: 'mdi-view-dashboard-outline',
            },
          ],
        },
        {
          subheader: 'Flux de travail',
          items: this.menuFluxElems,
        },
        {
          subheader: 'Suivi des équipes',
          items: this.menuTeamsElems,
        },
        {
          subheader: 'Administration',
          roles: ['ADMIN'],
          items: [
            {
              title: 'Utilisateurs',
              to: { name: 'Users' },
              icon: 'mdi-account-multiple-outline',
            },
            {
              title: 'Équipes',
              to: { name: 'Teams' },
              icon: 'mdi-account-supervisor-circle',
            },
            {
              title: 'Flux',
              to: { name: 'Flux' },
              icon: 'mdi-account-network',
            },
          ],
        },
      ]
    },
    menuFluxElems() {
      return map(sortBy(this.flux, ['name']), (f) => {
        const duplicationFlux = get(
          f,
          'settings.options.isARemoveDuplicateFlux'
        )
        let name = 'FluxBrowser'

        if (this.role !== 'ADMIN') {
          name = duplicationFlux ? 'FluxDuplicate' : 'FluxWork'
        }

        let count

        if (duplicationFlux) {
          count = get(this.fluxStats, `${f.id}.total`)
        } else if (this.role === 'VALIDATOR') {
          count = get(this.fluxStats, `${f.id}.treated`)
        } else {
          count = get(this.fluxStats, `${f.id}.created`)
        }

        if (!count) {
          count = 'OK'
        }
        else if (count === 500) {
          count = `${count}+`
        }

        return {
          title: f.name,
          to: { name, params: { id: f.id } },
          count,
          disabled: !f.active,
          icon: 'mdi-account-network',
        }
      })
    },
    menuTeamsElems() {
      return map(sortBy(this.teams, ['name']), (t) => {
        return {
          title: t.name,
          to: { name: 'TeamBrowser', params: { id: t.id } },
          icon: 'mdi-account-supervisor-circle',
        }
      })
    },
  },
  async created() {
    this.loading.flux = true

    const params = { size: 20 }
    await this.getFlux({ params })

    this.flux.forEach((f) => {
      if (f.active) {
        let state
        const duplicationFlux = get(f, 'settings.options.isARemoveDuplicateFlux')

        if (duplicationFlux) {
          // total
        } else if (this.role === 'VALIDATOR') {
          state = 'treated'
        } else {
          state = 'created'
        }
        this.getFluxStats({ fluxId: f.id, params: { state } })
      }
    })

    if (this.$route.name === 'Main') {
      if (this.role !== 'ADMIN' && this.flux && this.flux.length) {
        const duplicationFlux = get(
          this.flux[0],
          'settings.options.isARemoveDuplicateFlux'
        )
        const name = duplicationFlux ? 'FluxDuplicate' : 'FluxWork'
        this.$router.push({ name, params: { id: this.flux[0].id } })
      } else if (this.role === 'ADMIN') {
        this.$router.push({ name: 'Dashboard' })
      }
    }

    this.loading.flux = false

    this.loading.team = true
    await this.getTeams()
    this.loading.team = false

    Mapbox.accessToken = process.env.VUE_APP_MB_ACCESS_TOKEN;
    const map = new Mapbox.Map({
      container: 'mapbox-container',
      style: process.env.VUE_APP_MB_STYLE,
      zoom: 4.4,
      center: [2.2096669, 46.232192],
      attributionControl: false
    })

    this.$root.map = map
    map.addControl(new Mapbox.NavigationControl())
    map.on('load', () => {
      this.$root.$emit("mapboxReady")
    })
  },
  destroyed() {
    this.$root.map.remove()
  },
  methods: {
    ...mapActions({
      logout: 'auth/delete',
      getFlux: 'flux/getFlux',
      getTeams: 'teams/getTeams',
      getFluxStats: 'stats/getFluxStats',
    }),
    async logOut() {
      await this.logout().catch((e) => {
        this.openSnackbar(e, 'error')
      })
      if (!this.currentUser) {
        this.$router.push({ name: 'Login' })
      }
    },
    openSnackbar(message, color) {
      this.$root.$emit('openSnackBar', {
        message: message,
        color: color,
      })
    },
  },
}
</script>

<style>
html {
  overflow-y: auto !important;
}

.v-application .elevation-1 {
  box-shadow: 0 2px 30px -1px rgb(85 85 85 / 8%),
    0 4px 30px 0 rgb(85 85 85 / 6%), 0 1px 30px 0 rgb(85 85 85 / 3%) !important;
}

.elevation-minus-1 {
  box-shadow: inset 0 2px 30px -1px rgb(85 85 85 / 8%),
    inset 0 4px 30px 0 rgb(85 85 85 / 6%), inset 0 1px 30px 0 rgb(85 85 85 / 3%) !important;
}

.gradient {
  background-image: linear-gradient(to right bottom,
      #1f354f,
      #14617e,
      #0091a3,
      #34c2b8,
      #83f2be);
}
</style>

<style lang="scss" scoped>
#ts-main-appbar,
#ts-main-drawer {
  background: white;
}

#ts-main-main {
  background: #f2f5f8;
}

.ts-main-logo {
  max-width: 56px;
}

.pretty-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 33vh;
  transform: skewY(-2deg);
  transform-origin: 0 0;
  background-image: linear-gradient(to left bottom,
      #1f354f,
      #14617e,
      #0091a3,
      #34c2b8,
      #83f2be);
}
</style>
